import { delay, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { store } from "../..";
import { IAlert } from "../../interface/IInteractions";
import { interactionActions } from "../reducers/interactionsSlice";

export function* watchInteraction() {
    yield takeLatest(interactionActions.alertOpen.type, alertClose);
}

function* alertClose(data: PayloadAction<IAlert>) {
    if (!data.payload.keepOpen)
    {
        yield delay(5000)
        yield put(interactionActions.alertClose());
    }
}

function* dialogClose(data: PayloadAction<boolean>) {
    const dialog = store.getState().interactionReducer.dialog;
    if (data.payload === true) {
        dialog?.onYes();
    } else {
        dialog?.onNo?.();
    }
    yield put(interactionActions.dialogCloseSuccess());
}