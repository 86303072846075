import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, ImageList, ImageListItem, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { AddAPhoto } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { orderPostSliceActions } from '../store/reducers/orderSlice';
import { useTranslation } from 'react-i18next';
import { ICreateOrderModelWithImage } from '../interface/ICreateOrder';
import moment from 'moment';
import { Box } from '@mui/system';
import packageJson from "../../package.json";

interface iReinigungBeauftragenpageProps {

}
const ReinigungBeauftragenpage: React.FunctionComponent<iReinigungBeauftragenpageProps> = (props) => {
    const { t, i18n } = useTranslation();
    const MAXBESCHREIBUNGLAENGE = 255;
    const MAXIMAGES = 2;

    const [value, setValue] = useState<string>('defekt');
    const [textFieldHelperText, setTextFieldHelperText] = useState<string>("");
    const [textFieldValue, setTextFieldValue] = React.useState<string>();
    const [textFieldLabel, setTextFieldLabel] = React.useState<string>(t('reinigung.beschreibung'));
    const [file, setFile] = useState<{ content: string, fileName: string, contentType: string, contentBase64: string }[]>([]);
    const [fileName, setFileName] = useState<string>();
    const [dialogOpen, SetDialogOpen] = useState<boolean>(false);
    const [bereichId, setBereichId] = useState<string>();
    const [objektArt, setObjektArt] = useState<string>();
    const [unterbereichId, setUnterbereichId] = useState<string>();

    const dispatch = useDispatch();

    const queryparams = React.useMemo(() =>
        new URLSearchParams(window.location.search), []
    );
    React.useMemo(() => setBereichId(queryparams.get("bereichId") ?? undefined), [queryparams]);
    React.useMemo(() => {
        setObjektArt(queryparams.get("objektArt") ?? undefined)
    }, [queryparams]
    );
    React.useMemo(() => setUnterbereichId(queryparams.get("unterbereichId") ?? undefined), [queryparams]);

    const onChangeHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    }, []);

    const onChangeTextField = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length === MAXBESCHREIBUNGLAENGE) {
            setTextFieldHelperText(t('reinigung.beschreibung.error.text'));
            setTextFieldLabel(t('reinigung.beschreibung.error'))
        }
        if (event.target.value.length < MAXBESCHREIBUNGLAENGE) {
            setTextFieldHelperText("");
            setTextFieldValue(event.target.value);
            setTextFieldLabel(t('reinigung.beschreibung'));
        }

    }, []);

    const decode = React.useCallback((file: string): { "$content-type": string, "$content": string, "$contentBase64": string } => {
        const t1 = file.indexOf(':');
        const t2 = file.indexOf(';');
        const f1 = file.indexOf(',');

        return {
            "$content-type": file.substring(t1 + 1, t2),
            "$content": file.slice(f1 + 1),
            "$contentBase64": file
        }
    }, [])

    const onChangeImageButton = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files != undefined) {
            var reader = new FileReader();
            reader.onloadend = () => {
                const data = decode(reader.result as string);

                setFile([...file, { content: data.$content, fileName: event.target.files?.[0]?.name ?? "", contentType: data['$content-type'], contentBase64: data.$contentBase64 }]);
            }
            reader.readAsDataURL(event.target.files?.[0]);
        }
    }, [file, decode]);

    const DeleteImageHandler = React.useCallback((event: any) => {
        var newFile = file.filter(x => x.fileName !== fileName)
        setFile(newFile);
        SetDialogOpen(false);
    }, [file, fileName]);

    const OnClickButtonHandler = React.useCallback(() => {
        const data: ICreateOrderModelWithImage = {
            order: {
                order_Date: moment().unix(),
                order_UnterbereichId: unterbereichId !== undefined ? +unterbereichId : 0,
                order_BereichId: bereichId !== undefined ? +bereichId : 0,
                order_AufgabeID: 5, // muss geändert werden
                order_OrderStatusId: 1,
                order_MitarbeiterId: 93,
                order_ObjektArt: objektArt !== undefined ? +objektArt : 0
            },
            freitextFelder: [
                {
                    oFF_FTOId: 14,
                    oFF_Value: textFieldValue !== undefined ? textFieldValue : ""
                }
            ],
            image: file.map(x => {
                return {
                    imageContent: x.content,
                    imageContentType: x.contentType,
                    imageName: x.fileName
                }
            })
        };
        dispatch(orderPostSliceActions.CreateOrder(data));
        setFile([]);
        setTextFieldValue("");
    }, [textFieldValue, unterbereichId, bereichId, file, objektArt]);

    return (
        <>
            {(bereichId === (undefined || '') || unterbereichId === (undefined || '') || objektArt === (undefined || '')) &&
                <Stack justifyContent="center" alignItems="center">
                    <h1 style={{ color: "red" }}>{t('qr.code.scannen')}</h1>
                </Stack>
            }
            {(bereichId !== (undefined || '') && unterbereichId !== (undefined || '') && objektArt !== (undefined || '')) &&

                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >

                    <h1>{t('reinigung.beauftragen')}</h1>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >

                        <FormControl sx={{ width: [350, 550, 700] }}>
                            <FormLabel>{t('reinigung.waehlen')}</FormLabel>
                            <RadioGroup
                                row
                                value={value}
                                onChange={onChangeHandler}
                            >
                                <FormControlLabel value="defekt" control={<Radio />} label="Defekt" />
                                <FormControlLabel value="graffiti" control={<Radio />} label="Graffiti" />
                                <FormControlLabel value="kotze" control={<Radio />} label="Erbrochenes" />
                                <FormControlLabel value="dreck" control={<Radio />} label="Schmutz" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                    <FormControl sx={{ width: [350, 550, 700] }} variant="standard">
                        <TextField
                            label={textFieldLabel}
                            multiline
                            rows={3}
                            value={textFieldValue}
                            onChange={onChangeTextField}
                            variant="standard"
                            helperText={textFieldHelperText}
                            error={textFieldHelperText !== "" ? true : false}
                        />
                    </FormControl>
                    <Button color='primary' endIcon={<AddAPhoto />} variant="contained" component="label" disabled={file.length === MAXIMAGES}>
                        {t('bild.hochladen')}
                        <input hidden accept="image/*" capture="environment" type="file" onChange={onChangeImageButton} />
                    </Button>
                    <ImageList sx={{ width: 300, height: [150, 200, 200] }} cols={1} rowHeight={190}>
                        {file?.map(file =>
                            <ImageListItem key={file.fileName}>
                                <img
                                    // width="300"
                                    // height="200"
                                    src={file.contentBase64}
                                    alt={file.fileName}
                                    loading="lazy"
                                    onClick={() => { SetDialogOpen(true); setFileName(file.fileName) }}
                                />
                            </ImageListItem>
                        )}
                    </ImageList>
                    <Dialog
                        open={dialogOpen}
                        onClose={() => SetDialogOpen(false)}
                    >
                        <DialogTitle>
                            {t('bild.loeschen')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t('bild.loeschen.bestaetigen')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => SetDialogOpen(false)}>{t('nein')}</Button>
                            <Button onClick={DeleteImageHandler} autoFocus>{t('ja')}</Button>
                        </DialogActions>

                    </Dialog>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Button
                            variant='contained'
                            endIcon={<SendIcon />}
                            disabled={file.length === 0 || textFieldHelperText !== ""}
                            onClick={OnClickButtonHandler}>
                            {t('abschicken')}
                        </Button>
                        <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="space-evenly"
                            spacing={8}
                        >
                            <Button size='small' href="" >Datenschutz</Button>
                            <Box component="div" sx={{fontSize: "10px", pt: "8px"}}>{packageJson.version}</Box>
                            <Button size='small' href="https://ldm-it.azurewebsites.net/impressum">Impressum</Button>
                        </Stack>

                    </Stack>
                </Stack>
            }
        </>
    );
}
export default ReinigungBeauftragenpage;