import * as React from 'react';
import { IAlert } from '../interface/IInteractions';
import {Alert} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface iAlertProps {
    alert: IAlert | null,
    close: () => void,
    zIndex?: number
}
const AlertComponent: React.FunctionComponent<iAlertProps> = (props) => {
    const [t, i18n] = useTranslation()
    
    const style: React.CSSProperties = {
        position: "absolute",
        zIndex: props.zIndex || 2147483647 - 2,
        margin: 'auto',
        left: 0,
        right: 0,
        marginTop: 15,
        maxWidth: 800,
        width: "90%"
    };
    return props.alert ?
        <div style={style}>
            <Alert
                variant={props.alert.variant} severity={props.alert.severity}
            >
                {props.alert.success ? t('alert.success') : t('alert.error')}
            </Alert>
        </div> :
        null;
}
export default AlertComponent;