import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ICreateOrderModelWithImage } from "../../interface/ICreateOrder";

const stateOrder = {

}

const orderPostSlice = createSlice({
    name: "postOrder",
    initialState: stateOrder,
    reducers: {
        CreateOrder: (state, action: PayloadAction<ICreateOrderModelWithImage>) => {
        }
    }
})

export default orderPostSlice;
export const orderPostSliceActions = orderPostSlice.actions;