import axios from 'axios';

const URL = process.env.NODE_ENV === "production" ?"https://ldm-it.azurewebsites.net": "https://localhost:44397";

const axiosLdm =
    axios.create({
        baseURL: `${URL}/api/PassengerApp/v1`,
        headers: {
            'Content-Type': 'application/json',
            
        },
        timeout: (60 * 1000)
    })


    

export default axiosLdm;

