import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import orderPostSlice from './store/reducers/orderSlice';
import createSagaMiddleware from 'redux-saga';
import { watchPostOrder } from './store/saga/postOrderSaga';
import { Provider } from 'react-redux';
import App from './App';
import './i18n';
import interactionSlice from './store/reducers/interactionsSlice';
import { watchInteraction } from './store/saga/interaction';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  (process.env.NODE_ENV !== "production" &&
    typeof window !== "undefined" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export type reduxState = ReturnType<typeof appReducer>;
const appReducer = combineReducers({
  orderReducer: orderPostSlice.reducer,
  interactionReducer: interactionSlice.reducer
})
export const store = createStore(
  appReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(watchPostOrder);
sagaMiddleware.run(watchInteraction);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


