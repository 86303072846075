import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import axiosLdm from "../../axiosConfig/axios";
import { interactionActions } from "../reducers/interactionsSlice";
import { orderPostSliceActions } from "../reducers/orderSlice";
export function* watchPostOrder() {
    yield all([
        takeLatest(orderPostSliceActions.CreateOrder.type, createRequest)
    ])
}

function* createRequest(action: PayloadAction<any>) {
    yield put(interactionActions.spinnerOpen());

    const route = endpointRoute(action.type);

    yield call(createData, [route, action.payload]);

    yield put(interactionActions.spinnerClose());
}

function* createData(dataType: any) {
   
    try {
        const response = yield axiosLdm.post(dataType[0], dataType[1], {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("ldmToken"),
            },
            data: dataType[1]
        });
        yield put(interactionActions.alertOpen({
            severity: "error",
            variant: "filled",
            keepOpen: false,
            success: true
        }))
        return response.data;
    } catch (e) {
        yield put(interactionActions.alertOpen({
            severity: "error",
            variant: "filled",
            keepOpen: false,
            success: false

        }))
        if (e.message === "timeout of 60000ms exceeded") {
            return;
        }
        return;
    }
}


const endpointRoute = (type: string): string => {
    let endpointRoute = type.replace("postOrder/", "");

    return endpointRoute;
}