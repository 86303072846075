import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxState } from ".";
import AlertComponent from "./compontents/Alert";
import ReinigungBeauftragenpage from "./page/ReinigungBeauftragenPage";
import { interactionActions } from "./store/reducers/interactionsSlice";



const App: React.FunctionComponent<{}> = (props) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const dispatcher = useDispatch();
  const alert = useSelector((state: reduxState) => state.interactionReducer.alert);
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <AlertComponent zIndex={1500} alert={alert} close={() => dispatcher(interactionActions.alertClose)}/>
      <CssBaseline />
      <ReinigungBeauftragenpage />
    </ThemeProvider>
  );
}

type iMapStateToProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: reduxState) => ({
    interaction: state.interactionReducer,


})
type iMapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch) => {
    return {
        alertClose: () => dispatch(interactionActions.alertClose()),
    }
}
export default App;