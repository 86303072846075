import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from './translations/locales/en/translation.json'
import translationDE from './translations/locales/de/translation.json'

const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE,
    }
}

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

i18next.use(LanguageDetector).use(initReactI18next).init({
    resources,
    detection: options,
    fallbackLng: "en",
    compatibilityJSON: 'v3',
    interpolation: {
      escapeValue: false
    }
  
  })

  export default i18next;