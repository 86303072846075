import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAlert, IDialog, IInteraction } from "../../interface/IInteractions";

const state: IInteraction = {
  alert: null,
  dialog: null,
  spinner: false,
};

const interactionSlice = createSlice({
  name: "interactions",
  initialState: state,
  reducers: {
    alertOpen(state, action: PayloadAction<IAlert>) {
      state.alert = action.payload;
    },
    alertClose: (state) => {
      state.alert = null;
    },
    dialogOpen: (state, action: PayloadAction<IDialog>) => {
      state.dialog = action.payload
    },
    dialogClose: (state, action: PayloadAction<boolean>) => {},
    dialogCloseSuccess: (state) => {
      state.dialog = null;
    },
    spinnerOpen: (state)=>{
      state.spinner = true;
    },
    spinnerClose: (state)=>{
      state.spinner = false;
    }
  }
});
export default interactionSlice;
export const interactionActions = interactionSlice.actions;
